<template>
    <div class="login">
        <div class="login-wrap">
            <video class="login-video"  autoplay muted loop>
                <source src="/image/login-bg.mp4">
            </video>
            <div class="ms-login">
                <div class="ms-logo-img">
                    <img src="/image/logo.svg" alt="">
                </div>
                <el-form :model="param" :rules="rules" ref="login" label-width="0px" class="ms-content">
                    <h3>{{ $t('data.login.signIn') }}</h3>
                    <div class="login-text">
                        <p>{{ $t('data.login.newUser') }}?&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<el-link :underline="false" href="/register">{{ $t('data.login.createAccount') }}</el-link></p>
                    </div>
                    <el-form-item prop="username">
                        <el-input v-model="param.username" :placeholder="$t('data.login.userName')">
                            <template #prepend>
                                <el-button icon="el-icon-user"></el-button>
                            </template>
                        </el-input>
                    </el-form-item>
                    <el-form-item prop="password" style="margin-bottom: 5px;">
                        <el-input type="password" :placeholder="$t('data.login.password')" v-model="param.password"
                            show-password
                            @keyup.enter="submitForm()">
                            <template #prepend>
                                <el-button icon="el-icon-lock"></el-button>
                            </template>
                        </el-input>
                    </el-form-item>
                    <el-form-item prop="signed">
                        <el-checkbox v-model="param.signed">{{ $t('data.login.keepSigned') }}</el-checkbox>
                    </el-form-item>
                    <div class="login-btn">
                        <el-button type="primary" @click="submitForm()">{{ $t('data.login.signIn') }}</el-button>
                    </div>
                    <p class="login-tips"><el-button type="text" style="color: #fff;" size="mini" @click="toForget">{{ $t('data.login.forgetPassword') }}?</el-button></p>
                </el-form>
            </div>
        </div>
    </div>
</template>

<style scoped>
.login{
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.login-wrap {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #224486;
    background-size: 100%;
}

.login-video{
    min-width: 100%;
    min-height: 100%;
}
.ms-logo-img{
    width: 100%;
    height: 100px;
    text-align: center;
    border-bottom: 1px solid #ddd;
}
.ms-logo-img img{
    max-height: 100px;
    width: auto;
}
.ms-login {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 350px;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    overflow: hidden;

}
.ms-login::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(20px);
    -o-backdrop-filter: blur(20px);
    -ms-backdrop-filter: blur(20px); 
    -moz-backdrop-filter: blur(20px); 
    -webkit-backdrop-filter: blur(20px); 
}
.ms-content {
    padding: 30px 30px;
}
.ms-content h3{
    color: #fff;
}
.ms-content .el-checkbox{
    color: #fff;
}
.login-text p{
    color: #fff;
    font-size: 12px;
    margin: 15px 0px;
}
.login-text p a{
    color: #03cad5 !important;
}

.login-btn {
    text-align: center;
}
.login-btn button {
    width: 100%;
    height: 36px;
    margin-bottom: 10px;
}
.login-tips {
    font-size: 12px;
    line-height: 30px;
    color: #fff;
    text-align: center;
}
</style>

<script>
import { ref, reactive, computed } from "vue";
import { useRouter } from "vue-router";
import { ElMessage } from "element-plus";
import { useStore } from "vuex"
import { userLogin } from '../../api/index'
export default {
    name: 'login',
    setup() {
        const router = useRouter();
        const store = useStore();
        const param = reactive({
            username: "",
            password: "",
            signed: false
        });
        const rules = {
            username: [
                {
                    required: true,
                    message: "Please enter your username",
                    trigger: "blur",
                },
            ],
            password: [
                { required: true, message: "Please enter your password", trigger: "blur" },
            ],
        };
        const login = ref(null);
        const submitForm = () => {
            login.value.validate((valid) => {
                submitLogin();
            });
        };
        const submitLogin = () => {
            let apiData = {
                account: param.username,
                password: param.password
            };
            userLogin({apiData}).then( res => {
                ElMessage.success("success");
                localStorage.setItem("userInfo", JSON.stringify(res.data));
                localStorage.setItem("ms_username", res.data.info.branch.name);
                store.commit('updateAuth', res.data.info.role);
                router.push("/");
            })
        };
        return {
            param,
            rules,
            login,
            submitForm,
            submitLogin
        };
    },
    mounted(){
    },
    methods: {
        toForget(){
            window.location.href = "/forget"
        }
    }
};
</script>